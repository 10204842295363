import { colors } from "../styles/variables";

export const HEADER_CONFIG = {
    headerTitle: "Nasi pracownicy",
    subheader1: 'Wychowawcy w placówce "Ignaś"',
    subheader2: 'Wychowawcy w placówce "Helenka"',
};

export const MAIN_PERSON_CARDS = {
    header: "W Placówce Opiekuńczo – Wychowawczej „Ignaś” i „Helenka” zatrudnionych jest 17 pracowników, w tym: 2 pracowników administracji i obsługi oraz 15 pracowników pedagogicznych: dyrektor, pracownik socjalny, pedagog, psycholog i wychowawcy.",
    cards: [
        {
            id: 1,
            name: "Agata Kuczyńska",
            jobPosition: "Dyrektor",
        },
        {
            id: 2,
            name: "Marzena Marchela",
            jobPosition: "Specjalista pracy socjalnej",
        },
        {
            id: 3,
            name: "Katarzyna Kalbarczyk",
            jobPosition: "Pedagog",
        },
        {
            id: 4,
            name: "Weronika Tokarska – Koroś",
            jobPosition: "Psycholog",
        },
        {
            id: 5,
            name: "Krystyna Jóźwiak",
            jobPosition: "Pracownik administracyjno-gospodarczy",
        },
        {
            id: 6,
            name: "Maciej Bronisz",
            jobPosition: "Kierowca",
        },
    ],
};

export const IGNAS_PERSON_CARDS = {
    cards: [
        {
            id: 1,
            name: "Marta Pudlis",
            jobPosition: 'Koordynator w placówce "Ignaś", Wychowawca',
        },
        {
            id: 2,
            name: "Dorota Bala",
            jobPosition: "Wychowawca",
        },
        {
            id: 3,
            name: "Aleksandra Kochanowicz",
            jobPosition: "Wychowawca",
        },
        {
            id: 4,
            name: "Jolanta Prochacka – Cyrych",
            jobPosition: "Wychowawca",
        },
        {
            id: 5,
            name: "Grażyna Szczechura",
            jobPosition: "Wychowawca",
        },
        {
            id: 6,
            name: "Jakub Deptuła",
            jobPosition: "Wychowawca",
        },
        {
            id: 7,
            name: "Małgorzata Krzycka",
            jobPosition: "Wychowawca",
        },
    ],
};

export const HELENKA_PERSON_CARDS = {
    cards: [
        {
            id: 1,
            name: "Iza Pólkowska",
            jobPosition: 'Koordynator w placówce "Helenka", Wychowawca',
        },
        {
            id: 2,
            name: "Mariola Kurowska",
            jobPosition: "Wychowawca",
        },
        {
            id: 3,
            name: "Anna Kowalczyk",
            jobPosition: "Wychowawca",
        },
        {
            id: 4,
            name: "Ewa Świętoń",
            jobPosition: "Wychowawca",
        },
        {
            id: 5,
            name: "Dariusz Karski",
            jobPosition: "Wychowawca",
        },
        {
            id: 6,
            name: "Agnieszka Grodzka",
            jobPosition: "Wychowawca",
        },
    ],
};

export const CONTENT_RECTANGLES_CONFIG = {
    content: [
        {
            id: 1,
            background: colors.ddGreen200,
            header: 'Placówka pełniąca obsługę finansową jednostki P.O.W. "Ignaś"',
            description: [
                {
                    id: 1,
                    text: "Zespół Szkół Ponadpodstawowych w\u00A0Łochowie",
                },
                {
                    id: 2,
                    text: "ul. Wyspiańskiego 18",
                },
                {
                    id: 3,
                    text: "07-130 Łochów",
                },
            ],
            contact: {
                label: "Sekretariat",
                items: [
                    {
                        id: 1,
                        type: "phone",
                        label: "530 907 234",
                        value: "+48530907234",
                    },
                    {
                        id: 2,
                        type: "email",
                        value: "sekretariat@zsplochow.pl"
                    },
                ],
            },
        },
        {
            id: 2,
            header: "Księgowość",
            description: [
                {
                    id: 1,
                    text: "Główny Księgowy: Agnieszka\u00A0Puchta",
                },
                {
                    id: 2,
                    text: "Izabela Bzdak",
                },
            ],
            contact: {
                items: [
                    {
                        id: 1,
                        type: "phone",
                        label: "533 216 824",
                        value: "+48533216824",
                    },
                    {
                        id: 2,
                        type: "email",
                        value: "apuchta1@zsplochow.pl"
                    },
                    {
                        id: 3,
                        type: "email",
                        value: "ibzdak@zsplochow.pl"
                    },
                ],
            },
        },
        {
            id: 3,
            background: colors.ddGrey200,
            header: "Kadry i płace",
            description: [
                {
                    id: 1,
                    text: "Katarzyna Borsow",
                },
            ],
            contact: {
                items: [
                    {
                        id: 1,
                        type: "phone",
                        label: "533 193 849",
                        value: "+48533193849",
                    },
                    {
                        id: 2,
                        type: "email",
                        value: "kborsow@zsplochow.pl"
                    },
                ],
            },
        },
    ]
};
