import React from 'react';

import PageLayout from "../components/layout/PageLayout";
import SubsiteHeader from '../components/molecules/SubsiteHeader';
import { ContentRectanglesSection, PersonCardSection } from '../components/sections';
import Seo from '../components/seo';

import {
    HEADER_CONFIG,
    MAIN_PERSON_CARDS,
    IGNAS_PERSON_CARDS,
    HELENKA_PERSON_CARDS,
    CONTENT_RECTANGLES_CONFIG
} from '../content/employees_content_config';

const EmployeesPage = ({ location }) => {
    const { headerTitle, subheader1, subheader2 } = HEADER_CONFIG;
    
    return (
        <>
            <Seo
                title="Kadra | P.O.W. Ignaś i Helenka w Kaliskach"
                description="Profesjonalna kadra Placówek Ignaś i Helenka w Kaliskach zapewnia całodobową opiekę psychologów, pedagogów i wychowawców wszystkim naszym podopiecznym."
            />

            <PageLayout location={ location }>
                <SubsiteHeader title={ headerTitle } />

                <PersonCardSection config={ MAIN_PERSON_CARDS } />

                <SubsiteHeader title={ subheader1 } internal />

                <PersonCardSection config={ IGNAS_PERSON_CARDS } />

                <SubsiteHeader title={ subheader2 } internal />

                <PersonCardSection config={ HELENKA_PERSON_CARDS } />

                <ContentRectanglesSection config={ CONTENT_RECTANGLES_CONFIG } />
            </PageLayout>
        </>
    );
};
 
export default EmployeesPage;
